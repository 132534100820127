//TODO: Refactorizar el código
const fideicomiso = {
    figlo1: [2, 3, 5, 6],
    figlo2: [2, 3, 5, 6],
    figlo3: [2, 3, 5, 6],
    figlo4: [2, 3, 5, 6],
    figlo5: [2, 3, 5, 6],
    figlo6: [2, 3, 5, 6],
    figlo7: [2, 3, 5, 6],
    figlo8: [2, 3, 5, 6],
    figlo9: [2, 3, 5, 6],
    figlo10: [2, 3, 5, 6],
    figlo11: [2, 3, 4, 5, 6], // diferente
    figlo12: [2, 3, 5, 6],
    figlo13: [2, 3, 5, 6],
    figlo14: [2, 3, 4, 5, 6], // figlostase
    figlo15: [2, 3, 5, 6],
    figlo16: [2, 3, 5, 6],
    figlo17: [2, 3, 5, 6],
    figlo18: [2, 3, 4, 5, 6], //figlosnte 27

    organos: [
        { id: 1, value: "REPRESENTANTES DE SOCIOS" },
        { id: 2, value: "ASISTENTE REGIONAL" },
        { id: 3, value: "COMITÉ TÉCNICO" },
        { id: 4, value: "PATRONATO DE OBRAS" },
        { id: 5, value: "CONSEJO DE VIGILANCIA" },
        { id: 6, value: "COMISIÓN DE AUDITORÍA" },
        { id: 7, value: "COMISIÓN DE ESTILO Y REDACCIÓN" },
    ],

    typeFide: {
        figlo: [
            {
                id: 1,
                name: "FIGLOSNTE 2"
            },
            {
                id: 2,
                name: "FIGLOSNTE 8"
            },
            {
                id: 3,
                name: "FIGLOSNTE 13"
            },
            {
                id: 4,
                name: "FIGLOSNTE 16"
            },
            {
                id: 5,
                name: "FIGLOSNTE 23"
            },
            {
                id: 6,
                name: "FIGLOSNTE 25"
            },
            {
                id: 7,
                name: "FIGLOSNTE 26"
            },
            {
                id: 8,
                name: "FIGLOSNTE 32"
            },
            {
                id: 9,
                name: "FIGLOSNTE 33"
            },
            {
                id: 10,
                name: "FIGLOSNTE 47"
            },
            {
                id: 11,
                name: "FIGLOSNTE 53"
            },
            {
                id: 12,
                name: "FIGLOSNTE 55"
            },
            {
                id: 13,
                name: "FIGLOSNTE 56"
            },
            {
                id: 14,
                name: "FIGLOSTASE"
            },
            {
                id: 15,
                name: "FIGLOSNTSA"
            },
            {
                id: 18,
                name: "FIGLOSNTE 27"
            }
        ],
        jubicom: [
            {
                id: 16,
                name: "JUBICOM 16"
            },
            {
                id: 17,
                name: "JUBICOM 27"
            }
        ],
    }

}


export const setStructure = (list = [], unir = false) => {
    // console.log(list, '>>> lista de set structure')

    const aux = {
        figlo: [],
        jubicom: [],
    }

    if (list?.hasOwnProperty("categories")) list = list.categories;
    if (!list || !list.length) return aux;

    // 1. Recorrect el arreglo de categorias
    // 2. Evaluar el nombre de cada elemento y verificar si cumple con una u otra expresión regular
    // 3. Si cumple con figlo (ejemplo), añadimos el nombre del figlo a un arreglo
    // 4. hacer un filter del typeFide y regresamos los objetos que esten dentro del arreglo

    list.forEach(function (item) {
        if (/FIGLOS\w/g.test(item.name)) aux.figlo.push(item.name)

        if (/JUBICOM\s\w/g.test(item.name)) aux.jubicom.push(item.name)
    })

    aux.figlo = fideicomiso.typeFide.figlo.filter(item => aux.figlo.includes(item.name))

    aux.jubicom = fideicomiso.typeFide.jubicom.filter(item => aux.jubicom.includes(item.name))

    if (unir) return [...aux.figlo, ...aux.jubicom]

    return aux
}

export const getIdsFromFiglos = (figlos) => {
    if (!figlos || !figlos.length) return '';

    return figlos.map((figlo) => figlo.id).join(',');
}


export const getOrganos = (figlo, organoType) => {
    if (!fideicomiso[figlo]) return []

    if (parseInt(organoType) === 2) {
        return [fideicomiso.organos[0]] // REPRESENTANTES DE SOCIOS
    }

    if (parseInt(organoType) === 3) {
        return [{ id: 8, value: "CORPORATIVO" }] // CORPORATIVO
    }

    const data = fideicomiso.organos.filter((item) => fideicomiso[figlo].includes(item.id))
    return data
}

// Retorna el id de un comite (organo)
// const getIdFromName = (name) => {
//     if (!name) return 0;

// }

export const getNameFromId = (id) => {
    try {
        if (!id) return '';

        const aux = fideicomiso.organos.filter((item) => item.id === id);
        return aux[0].value;
    } catch (error) {
        console.log(error)
        return ""
    }
}

const comites = (comite) => {
    switch (comite.toUpperCase()) {
        case "REPRESENTANTES DE SOCIOS":
        case "REPRESENTANTE DE SOCIO":
        case "REPRESENTANTES DE SOCIO":
            return "REPRESENTANTES DE SOCIO";

        case "ASISTENTE REGIONAL":
        case "ASISTENTES REGIONALES":
            return "ASISTENTES REGIONALES";
        case "COMITÉ TÉCNICO":
        case "COMITE TECNICO":
            return "COMITÉ TÉCNICO";

        case "PATRONATO DE OBRAS":
        case "PATRONATO":
            return "PATRONATO";
        // case "CONSEJO DE VIGILANCIA":
        //   return comite;
        // case "COMISIÓN DE AUDITORÍA":
        //   return comite;

        default:
            return comite;
    }
};

export const getCohortName = (fide, comite) => {
    if (!fide) return "";

    let aux = "";
    if (fide === "FIGLOSTASE") aux = "FST";
    else if (fide === "FIGLOSNTSA") aux = "FSN";
    else if (!aux) {
        aux = `${fide.charAt(fide.length - 2)}${fide.charAt(
            fide.length - 1
        )}`.trim();
    }


    fide = !isNaN(aux) ? fide.charAt(0) + aux : aux;
    return `${fide} - ${comites(comite)}`;
};

export const formatNameExcel = (infideicomiso, orderBy) => {
    var auxName = "";
    if (infideicomiso === -1) auxName = "Fideicomisos";
    if (infideicomiso === -2) auxName = "Managers";
    if (infideicomiso > 0) {
        // obtenems el id de todos los fideicomisos
        var auxArrayFide = [...fideicomiso.typeFide.figlo, ...fideicomiso.typeFide.jubicom];
        auxArrayFide = auxArrayFide.filter((fide) => fide.id === infideicomiso);
        auxName += auxArrayFide[0]?.name ?? "Consulta"
    }

    return `${auxName}_${orderBy}_DAFI.xlsx`;
}

